import React, {Component} from "react";
import {BackendService} from "../../service/wallet_backend/BackendService";
import {Growl} from "primereact/growl";
import { Chart } from 'primereact/chart';
import {dateToString, getMonths, loadProfile, months} from "../../util/commonFunctions";
import {ValidationKYC} from "../KYC/ValidationKYC";
import {PendingInvoice} from "../InvoicePayment/PendingInvoice";
import {Balance} from "./Balance";
import {RecentAccountActivity} from "./RecentAccountActivity";

export class MainDashboard extends Component {
    constructor() {
        super();
        this.state = {
            profilePercentageData: {
                labels: ['Profile', "Pending"],
                datasets: [{
                    data: [0,100],
                    backgroundColor: ["#f7ab32","#1c2155"],
                    hoverBackgroundColor: ["#f7ab32","#1c2155"]
                }],
            },
            monthHistoryData: null,
            depositTransferData: null,
            totalBalance: null,
            pendingBalance: null,
            isLoading: true,

            rangeDate: null,
            selectedTransactionType:null,
            dataTableBestTrans: [],
            dataTableBestTransOrigin: [],
        }
        this.options = this.getLightTheme();
        this.backendService = new BackendService();
        this.loadHistory = this.loadHistory.bind(this);
        this.updateBalance = this.updateBalance.bind(this);
        this.loadRecentAccountActivity = this.loadRecentAccountActivity.bind(this);
        this.onTransactionTypeChange = this.onTransactionTypeChange.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
    }

    componentDidMount() {
        this.updateBalance();
        // send to payment method
        const saved_path = localStorage.getItem('saved_path')
        if(saved_path){
            localStorage.removeItem('saved_path')
            this.props.history.push(saved_path);
        }
        const {
            profilePercentageData,
        } = this.state;

        //let accountID = '0';
        loadProfile(this, this.backendService, null).then(resp => {
            if (resp !== null) {
                const profile = resp;
                if (profile !== undefined && profile !== null) {
                    profilePercentageData.datasets[0].data = [profile.kyc.porcentage, 100 - profile.kyc.porcentage];
                    this.setState({profilePercentageData});
                    /*
                    const arrayAccounts = profile.array_accounts;
                    if (arrayAccounts.length > 0) {
                        accountID = arrayAccounts[0].provider_accountid;
                        this.loadHistory(accountID);
                    }
                    */
                    //this.loadRecentAccountActivity();
                }
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getLightTheme() {
        let basicOptions = {
            legend: {
                labels: {
                    fontColor: '#f7ab32'
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: '#f7ab32'
                    },
                    gridLines: {
                        color: "#f7ab32",
                        lineWidth: 1,
                        zeroLineColor: "#f7ab32",
                        zeroLineWidth: 1
                    },
                }],
                yAxes: [{
                    ticks: {
                        fontColor: '#f7ab32'
                    },
                    gridLines: {
                        color: "#f7ab32",
                        lineWidth: 1,
                        zeroLineColor: "#f7ab32",
                        zeroLineWidth: 1
                    },
                }]
            },

        };
        let lightOptions = {
            legend: {
                labels: {
                    fontColor: '#f7ab32'
                }
            }
        };

        return {
            lightOptions,
            basicOptions
        }
    }

    loadHistory(accountID){
        this.backendService.postReportHistory({
            account_id: accountID,
            date_ini: '',
            date_end: '',
        }).then(resp2 => {
            if (resp2.status === 'success') {
                const lstDeposit = resp2.data.lstDeposit;
                const lstWithdraw = resp2.data.lstWithdraw;
                const totalData = resp2.data.total;
                const depositeData = [0,0,0,0,0,totalData.totalPayments];
                const withdrawData = [0,0,0,0,0,totalData.totalWithdraw];
                const labels = getMonths(6);

                const monthHistoryData={
                    labels,
                    datasets: [
                        {
                            label: 'Deposit',
                            backgroundColor: '#EDEDED',
                            data: depositeData
                        },
                        {
                            label: 'Transfer',
                            backgroundColor: '#f7ab32',
                            data: withdrawData
                        }
                    ]
                };

                const depositTransferData={
                    labels: months,
                    datasets: [
                        {
                            label: 'Deposit',
                            data: lstDeposit,
                            fill: false,
                            borderColor: '#EDEDED'
                        },
                        {
                            label: 'Transfer',
                            data: lstWithdraw,
                            fill: false,
                            borderColor: '#f7ab32'
                        }
                    ]
                };

                this.setState({
                    monthHistoryData,
                    depositTransferData
                });
            }
        }).catch(error => {
            console.error("error load account: ",error)
            if (this.growl !== null) {
                this.growl.show({severity: 'error', summary: 'Error', detail: "Can not load account/history/"});
            }
        });
    }

    updateBalance(){
        loadProfile(this, this.backendService, null).then(resp => {
            this.setState({
                totalBalance: localStorage.getItem("totalBalance"),
                pendingBalance: localStorage.getItem("pendingBalance"),
                isLoading: false,
            });

        });
    }

    loadRecentAccountActivity() {
        const self = this;
        self.setState({
            dataTableBestTrans: [],
            dataTableBestTransOrigin: [],
        });
        loadProfile(self, self.backendService, null).then(resp => {
            if (resp !== null) {
                const ROWFROM = resp.array_accounts;
                if(ROWFROM.length > 0){
                    const providerAccountid = ROWFROM[0].provider_accountid;
                    const params = {
                        account_id: providerAccountid,
                        date_ini: '',
                        date_end: '',
                    }

                    if(self.state.rangeDate !== null){
                        params.date_ini = dateToString(self.state.rangeDate[0]);
                        params.date_end = dateToString(self.state.rangeDate[1]);
                    }

                    self.backendService.postReportHistory(params).then(resp => {
                        const lstBestTrans = resp.data.lstBestTrans !== null ? resp.data.lstBestTrans : [];
                        lstBestTrans.forEach(function (element) {
                            if (element.TransactionDate != null) {
                                element.TransactionDate = element.TransactionDate.substring(0, 10);
                            }
                        });
                        self.setState({
                            dataTableBestTrans: lstBestTrans,
                            dataTableBestTransOrigin: lstBestTrans,
                        });
                    }).catch(error => {
                        //self.messages.show({severity: 'error', summary: 'Error', detail: `Error in Service recipients list ${error}`});
                    });
                }
            }
        });
    }

    onTransactionTypeChange(event) {
        if(event.value.length === 0){
            this.setState({dataTableBestTrans: this.state.dataTableBestTransOrigin});
        } else {
            const filter = [];
            this.state.dataTableBestTransOrigin.forEach(e => {
                event.value.forEach(v => {
                    if(v === e.TransactionDesc)
                        filter.push(e)
                });
            });
            this.setState({dataTableBestTrans: filter});
        }
        this.setState({selectedTransactionType: event.value});
    }

    changeHandler = event => {
        this.setState({[event.target.name]: event.target.value});
    }

    render() {
        const { isLoading} = this.state;

        if (isLoading) {
            return <div> Loading... </div>;}
            return (
            <div className="p-grid">
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}}/>
                <div className="p-col-12 p-sm-12 p-md-8 p-lg-8">
                    <div className="card">
                        <PendingInvoice props={this.props} updateBalance={this.updateBalance}/>
                    </div>
                </div>
                <div className="p-col-12 p-sm-12 p-md-4 p-lg-4">
                    <div className="card">
                        <Balance totalBalance={this.state.totalBalance} pendingBalance={this.state.pendingBalance}/>
                    </div>
                    <div className="card">
                        <h2> <i className="pi pi-envelope"></i> System Messages</h2>
                        <a href="/#/received_messages">Order status update</a><br/>
                        <a href="/#/received_messages">You have a pending invoice</a><br/>
                        <a href="/#/received_messages">View All</a>
                    </div>
                    <div className="card" style={{display: 'none'}}>
                        <div className="p-grid" style={{minHeight: 290}}>
                            <div className="p-col-12">
                                <h2>Profile Status</h2>
                            </div>
                            <div className="p-col-12 ta-center">
                                <Chart type="doughnut" data={this.state.profilePercentageData}
                                       style={{width: "222%", marginLeft: "-61%"}}
                                       options={this.options.lightOptions} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-12">
                    <RecentAccountActivity props={this.props}
                                           rangeDate={this.state.rangeDate}
                                           selectedTransactionType={this.state.selectedTransactionType}
                                           dataTableBestTrans={this.state.dataTableBestTrans}
                                           changeHandler={this.changeHandler}
                                           onTransactionTypeChange={this.onTransactionTypeChange}
                                           loadRecentAccountActivity={this.loadRecentAccountActivity}/>
                </div>
                <ValidationKYC history={this.props.history}/>
            </div>
        );
    }

}
