import React, {Component} from 'react';
import {Steps} from "primereact/steps";
import {Messages} from "primereact/messages";
import {Profile} from "../profile";
import {Identification} from "./Identification";
import {
    accepted,
    catCountry,
    countries,
    initialPage,
    loadProfile,
    validateKYCComplete
} from "../../util/commonFunctions";
import {BackendService} from "../../service/wallet_backend/BackendService";
import {Button} from "primereact/components/button/Button";
import {MERCHANT_KEY} from "../../util/constants";
import {Growl} from "primereact/growl";

export class KYC extends Component {

    constructor() {
        super();
        this.state = {
            activeProfileIndex: 0,
            activeDebug: false,
        };
        this.stepsProfile = [
            {label: 'Profile',},
            //{label: 'Face',},
            {label: 'Journey',},
            //{label: 'Account',}
        ];
        this.backendService = new BackendService();
        this.actionStep = this.actionStep.bind(this);
        this.backStep = this.backStep.bind(this);
        this.nextStep = this.nextStep.bind(this);
        this.initialStep = this.initialStep.bind(this);
        this.submitHandlerCompleteKYC = this.submitHandlerCompleteKYC.bind(this);
        this.statusProfile = this.statusProfile.bind(this);
    }

    componentDidMount() {
        this.statusProfile();
        const self = this;
        while (countries.length > 0) {
            countries.pop();
            catCountry.pop();
        }
        self.backendService.getCatalogs().then(resp => {
            if (resp.status === 'success') {
                resp.catCountries.forEach(element => {
                        catCountry.push({label: element[1], value: element[0]});
                        countries.push(element);
                    }
                );
            }
        }).catch(error => {
            self.messages.show({severity: 'error', summary: 'Error', detail: "Error load catalogs", life: 9000});
            if (error?.response?.status) {
                localStorage.clear();
                sessionStorage.clear();
                self.props.history.push("/login");
            }
        });
        this.backendService.getKYCStatus().then(kycResp => {
            if (kycResp.status === 'success') {
                const documents = kycResp.data;
                localStorage.setItem('documents', JSON.stringify(documents));
                this.initialStep();
            }
        }).catch(error => {
            if(self.growl !== null) {
                self.growl.show({severity: 'error', summary: 'Error', detail: "Error load KYC documents"});
            }
        });

        if(this.props.match.params['debug'] === "debug=true") {
            this.setState({activeDebug: true});
        }
    }

    actionStep = (index) => {
        this.backendService.getStatusKYC().then(resp => {
            const status_profile = resp.data.profile;
            if (status_profile === "verification.accepted") {
                if (index === 3 && validateKYCComplete() !== true) {
                    return;
                }
                this.setState({activeProfileIndex: index})
            } else {
                this.setState({activeProfileIndex: 0})
            }
        });
    }


    backStep () {
        const {activeProfileIndex} = this.state;
        if(activeProfileIndex > 0){
            this.setState({activeProfileIndex: activeProfileIndex - 1})
        }
    }

    nextStep () {
        this.backendService.getStatusKYC().then(resp => {
            const status_profile = resp.data.profile;
            if (status_profile === "verification.accepted") {
                const {activeProfileIndex} = this.state;
                if (activeProfileIndex === 1 && validateKYCComplete() !== true) {
                    return;
                }
                if (activeProfileIndex < 1) {
                    this.setState({activeProfileIndex: activeProfileIndex + 1})
                }
            } else {
                this.setState({activeProfileIndex: 0})
            }
        });
    }

    initialStep() {
        loadProfile(this, this.backendService, null).then(resp => {
            let activeProfileIndex = 0;
            const documentDict = JSON.parse(localStorage.getItem('documents'));
            const profile = documentDict !== null ? documentDict['profile']: null;
            //const face = documentDict !== null ? documentDict['face']: null;
            const document = documentDict !== null ? documentDict['document']: null;
            if(profile !== accepted)
                activeProfileIndex = 0;
            if (profile === accepted  && document !== accepted)
                activeProfileIndex = 1;
            if (validateKYCComplete() === true) {
                activeProfileIndex = 0;
            }

            this.setState({activeProfileIndex});
        });
    }

    submitHandlerCompleteKYC = () => {
        const self = this;
        if (validateKYCComplete() !== true) {
            const profileData = JSON.parse(localStorage.getItem('profile'));
            const updateProfileKYC = {
                merchant_key: MERCHANT_KEY,
                username: profileData.email,
                provider_userid: profileData.profile?.provider_userid,
                status_kyc: "3"
            }
            const createAccount = {
                merchant_key: MERCHANT_KEY,
                username: profileData.email,
                is_extern: false,
                provider_userid: profileData.profile?.provider_userid,
                type_provider: "5",
            }
            self.backendService.updateProfileKYC(updateProfileKYC).then(data => {
                self.backendService.createAccountBest(createAccount).then(data2 => {
                    loadProfile(self, self.backendService, null).then(resp => {
                        setTimeout(function () {
                            //self.props.history.push("/registerAccount");
                            self.nextStep();
                        }, 4000);
                    });
                }).catch(error => {
                    console.log("error: ", error);
                });
            }).catch(error => {
                console.log("error: ", error);
            });
        } else {
            self.props.history.push(initialPage);
        }
    }
    statusProfile() {
        this.backendService.getStatusKYC().then(resp => {
        });
    }



    render() {
        return (
            <div className="p-grid">
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}}/>
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-12 card">
                            <Steps model={this.stepsProfile} activeIndex={this.state.activeProfileIndex}
                                   onSelect={(e) => this.actionStep(e.index) } readOnly={false}/>
                        </div>
                        <div className="p-col-12 card"  style={{marginBottom: 0}} >
                            <div className="p-grid">
                                <div className="p-col-12 text-right">
                                    <Button icon="pi pi-arrow-left" className="small_button" onClick={this.backStep}/>
                                    <Button icon="pi pi-arrow-right" className="small_button" onClick={this.nextStep}/>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 card" >
                            <div className="p-grid">
                                <div className="p-col-12 ">
                                    <Messages ref={(el) => this.messages = el}></Messages>
                                    {this.state.activeProfileIndex === 0 &&
                                        <Profile openedInKYC="openedInKYC"
                                                 history={this.props.history}
                                                 nextStep={this.nextStep}/>}
                                    {this.state.activeProfileIndex === 1 &&
                                        <Identification history={this.props.history} nextStep={this.nextStep}
                                                        submitHandlerCompleteKYC={this.submitHandlerCompleteKYC}
                                                        activeDebug={this.state.activeDebug}/>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
