import React, {Component} from 'react';
import {BackendService} from "../../service/wallet_backend/BackendService";
import {Growl} from "primereact/growl";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {KycService} from "../../service/shuftipro/KycService";
import {accepted, catCountry, countries, isEmpty, loadProfile} from "../../util/commonFunctions";
import 'react-html5-camera-photo/build/css/index.css';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {ProgressSpinner} from "primereact/progressspinner";
import {Messages} from "primereact/messages";

export class Identification extends Component {

    static defaultProps = {
        nextStep: null,
        submitHandlerCompleteKYC: null
    }

    constructor() {
        super();
        this.state = {
            linkBank: null,
            isCompleted: false,
            status_kyc: null,
            status_kyc_desc: null,
            status_kyc_shufti: null,
            loadProfileReady: false,
            chargedYet: true,
        }
        this.backendService = new BackendService();
        this.confirmIdentification = this.confirmIdentification.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.handleChangeDown = this.handleChangeDown.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.confirmationDialog = this.confirmationDialog.bind(this);
        this.handleImageChangeFront = this.handleImageChangeFront.bind(this);
        this.handleImageChangeBack = this.handleImageChangeBack.bind(this);
        this.validate = this.validate.bind(this);
        this.searchCountry = this.searchCountry.bind(this);
        this.handleCameraFront = this.handleCameraFront.bind(this);
        this.handleClickOpenBack = this.handleClickOpenBack.bind(this);
        this.handleCameraBack = this.handleCameraBack.bind(this);
        this.getLinkKYC = this.getLinkKYC.bind(this);
        this.statusProfile = this.statusProfile.bind(this);
        this.historyKyc = this.historyKyc.bind(this);
    }

    componentDidMount() {
        this.messages.show({
            severity: 'info',
            summary: 'Info',
            detail: 'If you have not yet completed your KYC, click on the Generate button.',
            life: 15000
        });
        loadProfile(this, this.backendService, null).then(resp => {
        });
        const self = this;
        setTimeout(() => {
            self.setState({
                catCountries: countries,
                catCountry: catCountry
            });
        }, 300);
        const profileData = JSON.parse(localStorage.getItem('profile'));
        const documentDict = JSON.parse(localStorage.getItem('documents'));
        const document = documentDict !== null ? documentDict['document']: null;
        if (document === accepted)
            this.setState({identificationText: 'Accepted'});

        if ((document === accepted) &&
            (profileData?.profile?.country_birth !== null && profileData?.profile?.bday !== null) &&
            profileData?.kyc?.porcentage < 100){
            this.setState({isCompleted: true});
        }
        this.historyKyc();
    }
    statusProfile() {
        this.backendService.getStatusKYC().then(resp => {
            const status_kyc = resp.data.wallet_status_kyc;
            const status_kyc_desc = resp.data.wallet_status_kyc_desc;
            const status_kyc_shufti = resp.data.event
            this.setState({
                status_kyc,
                status_kyc_desc,
                status_kyc_shufti,
                loadProfileReady: true
            });
            this.getLinkKYC();
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error",
                life: 5000
            })
        });
    }

    historyKyc () {
        this.backendService.historyKYC().then(resp => {
            const historyKyc = resp.data;
            this.setState({
                historyKyc
            });
            //this.getLinkKYC();
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error",
                life: 5000
            })
        });
    }

    getLinkKYC() {
        const profileData = JSON.parse(localStorage.getItem('profile'));
        const email = profileData.email
        console.log(email)
        this.setState({chargedYet: false, linkBank: null});
            if (this.state.loadProfileReady === true ) {
                this.backendService.getLinkKYC().then(resp => {
                    if (resp["status code"] === 200 && resp.data != null) {
                        this.setState({
                            linkBank: resp.data.verification_url,
                            displayConfirm: false,
                            chargedYet: true
                        });
                        if (this.state.linkBank != null) {
                            this.growl.show({severity: 'success', detail: `If the link doesn't work, we have sent you the link to your email ${email}`, life: 15000});
                            this.historyKyc();
                        } else {
                            this.growl.show({severity: 'success', detail: resp.data.msg, life: 15000});
                        }
                    } else {
                        this.messages.show({
                            severity: 'error',
                            summary: 'Error',
                            detail: "Service Get Link KYC  doesn't work, it response null",
                            life: 9000
                        });
                        this.setState({displayConfirm: false, chargedYet: true});
                    }
                }).catch(error => {
                    console.log("ERROR: ", error);
                    this.growl.show({severity: 'error', summary: 'Error', detail: "Error"});
                    this.setState({displayConfirm: false, chargedYet: true});
                });
            } else {
            }
        }


    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    confirmIdentification = e => {
        e.preventDefault();
        this.setState({
            displayConfirmSend: true,
            isUploadFiles: true,
            identificationText: 'Submitted'
        });

        if (this.validate() && !this.state.isUploadFiles) {
            const kycService = new KycService();
            let doc_type = "";
            const country = this.state.countryIdentification;
            const frontBase64 = this.state.frontPreviewUrl.replace('data:image/;', 'data:image/jpeg;');
            const backBase64 = this.state.backPreviewUrl !== null ? this.state.backPreviewUrl.replace('data:image/;', 'data:image/jpeg;') : null;
            switch (this.state.identificationSelect) {
                case 3:
                    doc_type = 'id_card';
                    break;
                case 4:
                    doc_type = 'passport';
                    break;
                case 5:
                    doc_type = 'driving_license';
                    break;
                default:
                    break;
            }

            const shufParams = {
                id_type: doc_type,
                data_64: frontBase64,
                data_64_additional: backBase64,
                country: country,
                doc_type: doc_type,
                section_type: "IDENTIFICATION"
            };

            this.growl.show({severity: 'info', summary: 'info', detail: `${this.state.identificationDown.name} is being validated`, sticky: true});

            const self = this;
            self.setState({displayConfirm: false});

            kycService.postFace(shufParams).then(result => {
                const resultAcept = result['event'] === 'verification.accepted' ? true : false;
                const textAccepted = resultAcept ? 'Accepted' : result['declined_reason'];
                const type = 'document';

                self.growl.clear();
                if (resultAcept) {
                    self.messages.show({severity: 'success', summary: 'Success', detail: `Your ${doc_type} has accepted`});
                    const documents = JSON.parse(localStorage.getItem('documents'));
                    documents.document = accepted;
                    localStorage.setItem('documents', JSON.stringify(documents));

                    const profile = documents !== null ? documents['profile']: null;
                    const face = documents !== null ? documents['face']: null;
                    if (profile === accepted && face === accepted) {
                        self.messages.show({severity: 'success', summary: 'Success',
                            detail: 'Thank you for your information.  We are creating your account.', sticky: true});
                        this.setState({isCreatingAccount: true});
                        this.props.submitHandlerCompleteKYC();
                    } else {
                        let pending = profile !== accepted ? "profile" : "";
                        pending = face !== accepted ? pending + " and face": pending;
                        self.messages.show({severity: 'warning', summary: 'Warning', detail: 'Complete ' + pending, life: 9000});
                    }
                } else {
                    self.messages.show({severity: 'error', summary: 'Error', detail: `Your ${doc_type} has declined: ${result['declined_reason']}`, life: 9000});
                }
                self.setState({
                    workingShuftipro:false,
                    identificationText: textAccepted,
                    identificationCardDisplay: false,
                    displayConfirm: false,
                    displayConfirmSend: false,
                    frontPreviewUrl: null,
                    backPreviewUrl: null,
                    isUploadFiles: false,
                    frontFileExt: null,
                    backFileExt: null,
                });

                self.backendService.postKycCapture({
                    'responseKyc': result,
                    'type': type,
                    'doc_type': doc_type,
                }).then(result2 => {
                    console.log('Success stKycCapture: ' + JSON.stringify(result2));
                }).catch((e) => {
                    console.log('Error postKycCapture: ' + e);
                });
            }).catch((e) => {
                self.setState({
                    workingShuftipro:false,
                    identificationCardDisplay: false,
                    displayConfirm: false,
                    displayConfirmSend: false,
                    frontPreviewUrl: null,
                    backPreviewUrl: null,
                    isUploadFiles: false,
                    frontFileExt: null,
                    backFileExt: null,
                });
                console.log('Error kycService.postFace: ' + e);
            });
        }
    }

    changeHandler = e => {
        if (e.target.name === "countryIdentificationAuto" && e.target.value?.value !== undefined) {
            this.setState({
                [e.target.name]: e.target.value,
                countryIdentification: e.target.value?.value
            });
        } else {
            this.setState({[e.target.name]: e.target.value});
        }
    }

    handleChangeDown(e) {
        this.setState({
            identificationDown: e.value,
            identificationSelect : e.value.code });
    };

    showModal = (modal) => () => {
        const {countryIdentificationAuto, identificationDown} = this.state;
        if (isEmpty(countryIdentificationAuto) || isEmpty(identificationDown)) {
            this.messages.show({
                severity: 'error',
                summary: 'Error',
                detail: "Country and Identification are mandatory"
            });

        } else if (!isEmpty(countryIdentificationAuto) && isEmpty(countryIdentificationAuto.value)) {
            this.messages.show({
                severity: 'error',
                summary: 'Error',
                detail: "Select Country on click"
            });
        } else if(this.state.identificationText !== 'Accepted'){
            this.setState({
                [modal]: true,
                showCameraFront: false,
                showCameraBack: false
            });
        }
    }

    hideModal = modal => () => {
        this.setState({
            [modal]: false,
            displayConfirm: false,
        });
    }

    confirmationDialog = (nameModal, showModal, action) => {
        if(this.validate()){
            this.setState({
                [showModal]: true,
                actionToConfirm: action
            })
        }
    }

    handleImageChangeFront (e) {
        const files = Array.from(e.files);
        files.forEach(file => {
            const reader = new FileReader();

            reader.onloadend = () => {
                const name = file.name.split(".");
                const ext = name[name.length - 1] === 'pdf' ? file.name : null;
                this.setState({
                    frontFile: file,
                    frontPreviewUrl: reader.result,
                    frontFileExt: ext,
                    showCameraFront: false,
                });
            };
            reader.readAsDataURL(file);
        });
    };
    handleImageChangeBack (e) {
        const files = Array.from(e.files);
        files.forEach(file => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const name = file.name.split(".");
                const ext = name[name.length - 1] === 'pdf' ? file.name : null;
                this.setState({
                    backFile: file,
                    backPreviewUrl: reader.result,
                    backFileExt: ext,
                    showCameraBack: false,
                });
            };
            reader.readAsDataURL(file);
        });
    };

    validate() {
        if(this.state.frontPreviewUrl === null){
            this.growl.show({
                severity: 'error',
                summary: 'Error',
                detail: "Back image is mandatory"
            });
            return false;
        }
        return true;
    }

    searchCountry(event) {
        let filteredCountries;
        if (!event.query.trim().length) {
            filteredCountries = [...this.state.catCountry];
            this.setState({filteredCountries});
        } else {
            setTimeout(() => {
                filteredCountries = this.state.catCountry.filter((country) => {
                    return country.label.toLowerCase().includes(event.query.toLowerCase());
                });
                this.setState({filteredCountries});
            }, 250);
        }
    }


    handleCameraFront = (dataUri) => {
        const {
            showCameraFront,
        } = this.state;
        this.setState({
            frontPreviewUrl: dataUri,
            showCameraFront: !showCameraFront,
        });
    };
    handleClickOpenBack = () => {
        const {
            showCameraBack,
        } = this.state;

        this.setState({
            showCameraFront: false,
            showCameraBack: !showCameraBack,
        });
    };


    handleCameraBack = (dataUri) => {
        const {
            showCameraBack,
        } = this.state;
        // Do stuff with the photo...
        this.setState({
            backPreviewUrl: dataUri,
            showCameraBack: !showCameraBack,
        });
    };

    render() {
        const renderFooterConfirm = (nameModal, action) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel"
                        className="p-button-secondary" disabled={this.state.displayConfirmSend}/>
                <Button icon="pi pi-check" onClick={action} label="Confirm" disabled={this.state.displayConfirmSend}/>
            </div>
        );

        const confirmDialog = (
            <div>
                <Dialog header="Are you sure?" visible={this.state.displayConfirm} modal={true}
                        style={{width: '50vw', background: "#1c2155"}}
                        footer={renderFooterConfirm('displayConfirm', this.state.actionToConfirm)}
                        onHide={this.hideModal('displayConfirm')}>
                    <p>Are you, continue!</p>
                </Dialog>
            </div>
        );

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <Messages ref={(el) => this.messages = el} />
                    <Growl ref={(el) => this.growl = el} style={{marginTop: '75px', width: '320px'}}/>
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-6">
                                <h2 className="mb-20">KYC</h2>
                            </div>
                            <div className="p-col-6">
                                {this.state.chargedYet ? '' :
                                    <div className="col-9 ta-right"
                                         style={{position: "relative", paddingRight: 20,}}>
                                        <ProgressSpinner style={{width: '50px', height: '50px'}}
                                                         animationDuration=".7s"/>
                                    </div>
                                }
                            </div>
                            <div className="card ">
                                <div className="p-grid">
                                    <div className="p-col-12 text-center">
                                        {!this.state.linkBank &&
                                            <Button label="Generate" onClick={this.statusProfile}/>
                                        }
                                        {this.state.linkBank &&
                                            <a href={this.state.linkBank} target="_blank" rel="noopener noreferrer">
                                                <Button label="Begin KYC"/>
                                            </a>
                                        }
                                    </div>
                                    <div className="p-col-12">
                                        <DataTable value={this.state.historyKyc} selectionMode="single"
                                                   responsive={true}>
                                            <Column field='declined_reason' header='Status' className="ta-left"/>
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {confirmDialog}
            </div>
        );
    }
}
