import React, {Component} from 'react';
import {Route, withRouter} from 'react-router-dom';
import App from "./App";
import Login from "./pages/Login";
import SignUp from "./pages/signUp";
import {savePath} from "./util/commonFunctions";
import {AboutPayment} from "./components/payment/AboutPayment";
import Forgot_Password from "./pages/Forgot_Password";
import Privacy from "./pages/Privacy";
import {Bank_Account_Accepted} from "./components/BankAccounts/Bank_Account_Accepted";
import {Bank_Account_Canceled} from "./components/BankAccounts/Bank_Account_Canceled";
import {Kyc_Accepted} from "./components/KYC/Kyc_Accepted";
import {accepted} from "./components/KYC/accepted";
import ConfirmForgotPassword from "./pages/ConfirmForgotPassword";
import {Help} from "./pages/Help";

class AppWrapper extends Component {
    componentDidMount() {
        savePath(this.props.location.pathname);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        if (this.props.location.pathname.includes("/accept/payment/") && this.props.location.pathname.length > 20) {
            const payment_path = this.props.location.pathname
            savePath(payment_path)
            return <Route path="/accept/payment/:code/:merchant_key" component={AboutPayment}/>
        }else if (this.props.location.pathname.includes("/forgot/")) {
            return <Route path="/forgot/:idUser/:token/" component={ConfirmForgotPassword}/>
        } else {
            switch (this.props.location.pathname) {
                case "/login":
                    return <Route path="/login" component={Login}/>
                case "/privacy":
                    return <Route path="/privacy" component={Privacy}/>
                case "/forgot_password":
                    return <Route path="/forgot_password" component={Forgot_Password}/>
                case "/signUp":
                    return <Route path="/signUp" component={SignUp}/>
                case "/account_accepted":
                    return <Route path="/account_accepted" component={Bank_Account_Accepted}/>
                case "/account_canceled":
                    return <Route path="/account_canceled" component={Bank_Account_Canceled}/>
                case "/kyc_Accepted":
                    return <Route path="/kyc_Accepted" component={Kyc_Accepted}/>
                case "/accepted":
                    return <Route path="/accepted" component={accepted}/>
                case "/help":
                    return <Route path="/help" component={Help}/>
                default:
                    return <App/>;
            }
        }
    }
}

export default withRouter(AppWrapper);
