import React, {Component} from "react";
import {Messages} from "primereact/messages";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {MultiSelect} from "primereact/multiselect";
import PropTypes from "prop-types";
import {Calendar} from "primereact/calendar";
import {Button} from "primereact/button";
import {dateToString, loadProfile} from "../../util/commonFunctions";
import {Dropdown} from "primereact/dropdown";
import {BackendService} from "../../service/wallet_backend/BackendService";
import {ProgressSpinner} from "primereact/progressspinner";

export class RecentAccountActivity extends Component {
    static defaultProps = {
        rangeDate: null,
        selectedTransactionType: null,
        dataTableBestTrans: null,
        changeHandler: null,
        onTransactionTypeChange: null,
        loadRecentAccountActivity: null,
    }

    static propTypes = {
        rangeDate: PropTypes.array,
        selectedTransactionType: PropTypes.string,
        dataTableBestTrans: PropTypes.array.isRequired,
        changeHandler: PropTypes.func.isRequired,
        onTransactionTypeChange: PropTypes.func.isRequired,
        loadRecentAccountActivity: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
            dataTableBestTransSelected: {},
            accountid: null,
            rangeDate: null,
            chargedYet: true,
        }
        this.transaction_type = [
            {label: 'Paid someone via ACH?', value: 'Fund ACH (Origination)'},
            {label: 'P2P Sent/Received', value: 'Fund Transfer'},
            {label: 'Load Card', value: 'Load Card'},
            {label: 'Paid Invoice', value: 'Redeem Transfer'},
        ];

        this.loadRecentAccountActivity = this.loadRecentAccountActivity.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.backendService = new BackendService();

    }

    componentDidMount() {
        const self = this;
        loadProfile(self, self.backendService, null).then(resp => {
            if (resp !== null) {
             const accounts = resp.array_accounts;
             this.setState({accounts, accountid: accounts[0]});
             this.loadrecentAccountActivityDefault()
            }
        });

    }
    changeHandler = event => {
        this.setState({accountid: event.value});
    };
    loadrecentAccountActivityDefault() {
        let nowDate = new Date();
        let date_ini = new Date(nowDate.getFullYear(), nowDate.getMonth(), 1);
        let date_end = new Date(nowDate.getFullYear(), nowDate.getMonth() + 1, 0);
        const params = {
            account_id: this.state.accounts[0].provider_accountid,
            date_ini: dateToString(date_ini),
            date_end: dateToString(date_end),
        }
        console.log(params)
        this.backendService.postReportHistory(params).then(resp => {
            //const lstBestTrans = resp.data.lstBestTrans !== null ? resp.data.lstBestTrans : [];
            const lstWithdraw = resp.data.lstWithdraw !== null ? resp.data.lstWithdraw : [];
            /*lstBestTrans.forEach(function (element) {
                if (element.TransactionDate != null) {
                    element.TransactionDate = element.TransactionDate.substring(0, 10);
                }
            });*/
            this.setState({
                dataTableBestTrans: lstWithdraw,
                dataTableBestTransOrigin: lstWithdraw,
                chargedYet: true
            });
        }).catch(error => {
            this.setState({chargedYet: true});
            this.messages.show({
                severity: 'error',
                summary: 'Error',
                detail: `Error in Service recipients list ${error}`
            });
        });
    }

    loadRecentAccountActivity() {
        if (this.state.accountid !== null && this.state.rangeDate !== null) {
            const self = this;
            self.setState({
                dataTableBestTrans: [],
                dataTableBestTransOrigin: [],
                chargedYet: false
            });
            const params = {
                account_id: this.state.accountid.provider_accountid,
                date_ini: dateToString(this.state.rangeDate[0]),
                date_end: dateToString(this.state.rangeDate[1]),
            }
                self.backendService.postReportHistory(params).then(resp => {
                    const lstWithdraw = resp.data.lstWithdraw !== null ? resp.data.lstWithdraw : [];
                    //const lstBestTrans = resp.data.lstBestTrans !== null ? resp.data.lstBestTrans : [];
                    /*lstBestTrans.forEach(function (element) {
                        if (element.TransactionDate != null) {
                            element.TransactionDate = element.TransactionDate.substring(0, 10);
                        }
                    });*/
                    self.setState({
                        dataTableBestTrans: lstWithdraw,
                        dataTableBestTransOrigin: lstWithdraw,
                        chargedYet: true
                    });
                }).catch(error => {
                    this.setState({chargedYet: true});
                    self.messages.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: `Error in Service recipients list ${error}`
                    });
                });
        } else {
            this.messages.show({
                severity: 'info',
                detail: `Select range date in to calendar and Wallet name`
            });
        }
    }


    render() {
        const transactionType = <MultiSelect placeholder="Type" value={this.props.selectedTransactionType}
                                             style={{width: '100%', marginTop: 6}}
                                             options={this.transaction_type} onChange={this.props.onTransactionTypeChange} />;
        const dateTemplate = (rowData) => {
            let date = rowData.d_created.split("T")[0];
            return <span>{date}</span>;
        };
        const formatCurrency = (field) => {
            return (rowData) => parseFloat(rowData[field]).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        }
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <h2 className="mb-20">Recent Account Activity</h2>
                                <Messages ref={(el) => this.messages = el}></Messages>
                                <div className="grid">
                                    {this.state.chargedYet ? '' :
                                        <div className="col-9 ta-right" style={{position: "relative", marginTop: -60}}>
                                            <ProgressSpinner style={{width: '50px', height: '50px'}}
                                                             animationDuration=".7s"/>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="p-col-12">
                                <div className="p-fluid p-grid p-formgrid p-formgroup-inline">
                                    <div className="p-col-12 p-sm-6 p-xl-3">
                                        <Dropdown options={this.state.accounts}
                                                  placeholder="Wallet Name"
                                                  optionLabel="label"
                                                  name="label"
                                                  value={this.state.accountid}
                                                  onChange={this.changeHandler}
                                                  disabled={false}
                                                  autoWidth={false} />
                                        {/*{errors?.accountid !== undefined ?
                                            <small className="p-messages p-messages-error">{errors?.accountid}</small>
                                            : ""
                                        }*/}
                                    </div>
                                    <div className="p-col-12 p-sm-4 p-xl-4">
                                        <Calendar id="rangeDate"
                                                  tooltip= "Enter range"
                                                  tooltipOptions={{position: 'top'}}
                                                  placeholder="Range Date"
                                                  dateFormat="yy-mm-dd"
                                                  name="rangeDate"
                                                  selectionMode="range"
                                                  value={this.state.rangeDate}
                                                  onChange={(e) => this.setState({ rangeDate: e.value })}
                                                  showIcon monthNavigator/>
                                    </div>

                                    <div className="p-col-12 p-sm-4 p-xl-2">
                                        <Button   label="Search" onClick={() => this.loadRecentAccountActivity()}/>
                                        <small id="username2-help" className="p-error p-d-block">*Enter Date Range And Wallet Name</small>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12">
                                <DataTable value={this.state.dataTableBestTrans} selectionMode="single" paginator={true} rows={10}
                                           responsive={true} selection={this.state.dataTableBestTransSelected}
                                           onSelectionChange={event => this.setState({dataTableBestTransSelected: event.value})}>
                                    <Column field='id' header='Transaction Number' filter={true} className="ta-center"
                                            filterPlaceholder="Transaction Number"/>
                                    <Column field='d_created' header='Date' body={dateTemplate} className="ta-center" filter={true}
                                            filterPlaceholder="Date" sortable={true}/>
                                    <Column field='type_transfer' header='Description' className="ta-center"
                                            filter={true} filterElement={transactionType}  sortable={true}/>
                                    <Column field='reference' header='Reference' className="ta-center"
                                            filter={true} filterPlaceholder="Reference" sortable={true}/>
                                    <Column field='amount' header='Amount' body={formatCurrency('amount')} className="ta-center" filter={true}
                                            filterPlaceholder="Amount" sortable={true}/>
                                    <Column field='status_transfer' header='Status' className="ta-center" filter={true}
                                            filterPlaceholder="Balance" sortable={true}/>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
