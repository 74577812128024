import React, {Component} from "react";
import {BackendService} from "../../service/wallet_backend/BackendService";
import PropTypes from "prop-types";

export class Balance extends Component {
    static defaultProps = {
        totalBalance: 0,
        pendingBalance: 0,
    }
    static propTypes = {
        totalBalance: PropTypes.string,
        pendingBalance: PropTypes.string,
    }

    constructor(props) {
        super(props);
        this.state = {}
        this.backendService = new BackendService();
    }


    render() {
        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <h2 className="ta-right">Available Balance: {this.props.totalBalance}</h2>
                    <h2 className="ta-right">Pending Balance: {this.props.pendingBalance}</h2>
                </div>
            </div>
        )
    }
}
