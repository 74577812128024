import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {Button} from "primereact/button";
import {Growl} from 'primereact/growl';

import {BackendService} from "../service/wallet_backend/BackendService";
import {Checkbox} from "primereact/checkbox";
import {changeStyleSheetUrl, initialPage, loadProfile, validateKYCComplete} from "../util/commonFunctions";
import {InputText} from 'primereact/inputtext';


export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            usernameError: '',
            passwordError: '',
            login: false,
            errorLogin: '',
            messages: '',
            rand: Math.floor(Math.random() * (34 - 1)) + 1,
            height: window.innerHeight,
            usuario: this.datasource,
            visibleDialog: false,
            validando: false,
            redirectSignUp: false,
            checkedRemember: false,
        }
        this.items =  [
            {label: 'Why E-Wallet', icon: 'pi pi-fw pi-home'},
            {label: 'About Us', icon: 'pi pi-fw pi-calendar'},
            {label: 'Statistics', icon: 'pi pi-fw pi-pencil'},
            {label: 'Operational Capabilities', icon: 'pi pi-fw pi-file'}
        ];

        this._handleKeyPress = this._handleKeyPress.bind(this);
        this.checkSession = this.checkSession.bind(this);
        //this.validarLogueo = this.validarLogueo.bind(this);
        this.showMsg = this.showMsg.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.showError = this.showError.bind(this);
        this.showPassword = this.showPassword.bind(this);

        this.backendService = new BackendService();
    }

    componentDidMount() {
        changeStyleSheetUrl('layout-css', 'ewallet-v4', 'layout');
        changeStyleSheetUrl('theme-css', 'ewallet-v4', 'theme');
    }

    sendLogin = () => {
        this.props.history.push("/login");
    }

    sendSignUp = () => {
        this.props.history.push("/signUp");
    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.checkSession(e);
        }
    }

    /**
     * Metodo para mostrar mensaje de error cuando el usuario y/o password son incorrectos
     * params:
     * return: se habilita mensaje de error
     **/
    showError(msg) {
        let data = {severity: 'error', summary: 'ERROR', detail: msg};
        this.growl.show(data);
    }

    /**
     * Metodo para mostrar mensaje de alerta cuando el usuario y/o password estan vacios
     * params:
     * return: se habilita mensaje de alerta
     **/
    showWarn(msg) {
        let data = {severity: 'warn', summary: 'AVISO', detail: msg};
        this.growl.show(data);
    }

    /**
     * Metodo para mostrar mensaje de alerta generico
     * params:
     * return: se habilita mensaje de alerta
     **/
    showMsg(severityMsg, summaryMsg, detailMsg) {
        let msg = {severity: severityMsg, summary: summaryMsg, detail: detailMsg};
        this.growl.show(msg);
    }

    /**
     * Metodo para autenticacion del usuario
     * Se utiliza el serivicio AuthService
     * params: usurname y password
     * return: mensaje de error o acceso al sistema
     **/
    checkSession = (e) => {
        this.setState({validando: true})
        e.preventDefault();
        if (this.isValidForm()) {
            this.validarLogueo(this);
        } else {
            this.setState({validando: false});
            this.showWarn();
        }
    }

    /**
     * Metodo de validacion de usuario y password no se encuentren vacios
     * params: usurname y password
     * return: true o false
     **/
    isValidForm() {
        let isValid = true;
        let usernameError = '';
        let passwordError = '';

        usernameError = this.state.username === '' ? 'El Usuario es obligatorio' : '';
        passwordError = this.state.password === '' ? 'La Contraseña es obligatorio' : '';

        isValid = usernameError === '' && passwordError === '';
        return isValid;
    }

    validarLogueo(self) {
        this.backendService.postLogin({
            username: self.state.username.toLowerCase(), password: self.state.password
        }).then(accessResp => {
            if (accessResp.access) {
                const expiration = (Date.now() / 1000) + 1000;

                localStorage.setItem('userNameSesion', self.state.username);
                localStorage.setItem("access_token", accessResp.access);
                localStorage.setItem("expires_date", expiration.toString());
                localStorage.setItem("refresh_token", accessResp.refresh);
                localStorage.setItem('login', true);
                loadProfile(self, self.backendService, null).then(resp => {
                    if (resp !== null) {
                        let lstMerchants = JSON.stringify(resp.profile.lstMerchants);
                        let role = resp.profile.rol;
                        let phone = resp.profile.cell_number;
                        const userLogged = resp.first_name + ' ' + resp.last_name;

                        localStorage.setItem('lstMerchants', lstMerchants);
                        localStorage.setItem('role', role);
                        localStorage.setItem('phone', phone);
                        localStorage.setItem('userLogged', userLogged);
                        this.backendService.getKYCStatus()
                            .then(kycResp => {
                                if (kycResp.status === 'success') {
                                    const documents = kycResp.data;
                                    localStorage.setItem('documents', JSON.stringify(documents));
                                }
                                self.setState({login: true});
                                if (validateKYCComplete() !== true) {
                                    self.props.history.push("/kyc");
                                } else {
                                    self.props.history.push(initialPage);
                                }
                            }).catch(error => {
                                self.setState({login: true});
                                self.props.history.push(initialPage);
                                if(self.growl !== null) {
                                    self.growl.show({severity: 'error', summary: 'Error', detail: "Error load KYC documents"});
                                }
                        });
                    } else {
                        self.setState({validando: false});
                        self.growl.show({severity: 'error', summary: 'Error', detail: "Bad credentials"});
                    }
                });
            }
        }).catch(error => {
            self.setState({validando: false});
            if(self.growl !== null){
                self.growl.show({severity: 'error', summary: 'Error', detail: "User or password is wrong"});
            }
        });
    }

    cleanDataApproved(data) {
        let sectionDict = data !== null ? data['IDENTIFICATION'] : null;
        let sectionDictIdent = data !== null ? data['PROOF_ADDRESS'] : null;

        if (sectionDict !== null) {
            if (sectionDict.hasOwnProperty('Face')) {
                sectionDict = this.cleanData(sectionDict, 'Face');
            }
            if (sectionDict.hasOwnProperty('Identity Card No.')) {
                sectionDict = this.cleanData(sectionDict, 'Identity Card No.');
            }
            if (sectionDict.hasOwnProperty('Identity Card')) {
                sectionDict = this.cleanData(sectionDict, 'Identity Card');
            }
            if (sectionDict.hasOwnProperty('id_card')) {
                sectionDict = this.cleanData(sectionDict, 'id_card');
            }
            if (sectionDict.hasOwnProperty('Passport')) {
                sectionDict = this.cleanData(sectionDict, 'Passport');
            }
            if (sectionDict.hasOwnProperty('passport')) {
                sectionDict = this.cleanData(sectionDict, 'passport');
            }
            if (sectionDict.hasOwnProperty('Driver License')) {
                sectionDict = this.cleanData(sectionDict, 'Driver License');
            }
            if (sectionDict.hasOwnProperty('driving_license')) {
                sectionDict = this.cleanData(sectionDict, 'driving_license');
            }
            data['IDENTIFICATION'] = sectionDict;
        }
        if (sectionDictIdent !== null) {
            if (sectionDictIdent.hasOwnProperty('Utility Bill')) {
                sectionDictIdent = this.cleanData(sectionDictIdent, 'Utility Bill');
            }
            if (sectionDictIdent.hasOwnProperty('utility_bill')) {
                sectionDictIdent = this.cleanData(sectionDictIdent, 'utility_bill');
            }
            if (sectionDictIdent.hasOwnProperty('Bank Statement')) {
                sectionDictIdent = this.cleanData(sectionDictIdent, 'Bank Statement');
            }
            if (sectionDictIdent.hasOwnProperty('bank_statement')) {
                sectionDictIdent = this.cleanData(sectionDictIdent, 'bank_statement');
            }
            data['PROOF_ADDRESS'] = sectionDictIdent;
        }
        return data;
    }

    cleanData(data, name) {
        if (data[name]['status_kyc_desc'] === "Approved") {
            data[name]['front_data_64'] = "";
            data[name]['front_data_64'] = "";
        }
        data[name]['front_data_64'] = "";
        data[name]['front_data_64'] = "";
        return data;
    }
    showPassword () {
            var tipo = document.getElementById("password");
            if(tipo.type === "password"){
                tipo.type = "text";
            }else{
                tipo.type = "password";
            }
            return false;
        }

    render() {
        if (this.state.login) {
            return (<Redirect to={`/`}/>);
        }

        return (
            <div>
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}} />
                <div className="main">
                    <section className="sign-in">
                        <div className="container">
                            <div className="signin-content card">
                                <div className="signin-image">
                                    <figure><img src="assets/ewallet/img/logo_caribmall.png" alt="singImage"  style={{height: 125, width: 250, marginTop: 60, marginLeft: 10 }}/></figure>
                                    <a href="#/signUp" onClick={this.sendSignUp} className="signup-image-link"
                                       style={{textDecoration:"underline" /*color: "#0062cc"*/}}>Create an account</a>
                                </div>

                                <div className="signin-form">
                                    <h1 className="form-title">Sign in</h1>
                                        <div className="form-group">
                                            <span className="p-input-icon-left p-fluid">
                                                <i className="fa fa-user" />
                                                <InputText value={this.state.username} placeholder="email" onKeyPress={this._handleKeyPress}
                                                           onChange={(e) => this.setState({username: e.target.value})}  />
                                            </span>
                                        </div>
                                        <div className="form-group" >
                                            <span  className="p-inputgroup">
                                                <span className="p-input-icon-left">
                                                <i className="fa fa-lock"/>
                                                <InputText Style="padding-right: 130px;" name="password" value={this.state.password} placeholder="Password" id="password" onKeyPress={this._handleKeyPress} type="password"
                                                           onChange={(e) => this.setState({password: e.target.value})}/>
                                                </span>
                                                <Button icon="fa fa-eye" className="p-button-text"  Style="height: 30.4px ;background-color: #00283A;" onClick={this.showPassword}/>
                                            </span>
                                        </div>
                                        <div className="form-group">
                                            <Checkbox inputId="binary" checked={this.state.checkedRemember} onChange={e => this.setState({checkedRemember: e.checked})} />
                                            <label htmlFor="remember-me" style={{color: "#E08727"}}
                                                   className="label-agree-term"><span><span></span></span>Remember me</label>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-6">
                                                <a href="/#/forgot_password">Forgot Password?</a>
                                            </div>
                                            <div className="p-col-6 ta-right">
                                                <Button label="Sign In" style={{padding:12}}
                                                        className={this.state.validando ? 'secondary-btn' : "p-button-primary"}
                                                        disabled={this.state.validando} onClick={this.checkSession}
                                                />
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}
