import axios from 'axios';
import {EXPRESS} from "../../util/constants";

export class HeisenbergService {

    getConfig = params => {
        const heisenberg_access_token = 'V33ntOZLQGKH8Nba40ORU5glQlxWqh';

        return {
            params,
            headers: {
                Authorization: `Bearer ${heisenberg_access_token}`,
            },
        };
    };

    getConfigExpress = params => {
        const accessToken = 'V33ntOZLQGKH8Nba40ORU5glQlxWqh';
        return {
            params,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };
    };

    transferFromAccountToCard(params) {
        return axios.post(
            `${EXPRESS}/api/v/2.0/service/broker/transfer_a_to_c`,
            params,
            this.getConfig(),
        ).then(resp => {
            return resp.data;
        });
    }

}
